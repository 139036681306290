import { LitElement, customElement, html, css, unsafeCSS} from 'lit-element'
import { connect } from '@captaincodeman/rdx'
import { store } from '../store'
import { exit_to } from './icons'
import './scheduling-navbar'
import './hsuite-scheduling.ts'
import schedulingAppCSS from '../css/scheduling-app.css'
@customElement('scheduling-app')
class HSuiteSchedulingElement extends connect(store, LitElement) {

  render() {
    return html`
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
      <div class="app-layout">
        <i class="inputicon material-icons md-36 ">${exit_to}</i>
        <i class="appsicon material-icons md-36 ">apps</i>
        <div class="app-drawer">
          <img class="brand-logo" src="images/logo.png">
          <scheduling-navbar></scheduling-navbar>
        </div>
        <hsuite-scheduling></hsuite-scheduling>
      </div>    
    `
  }

  static get styles() {
    return css`
        ${unsafeCSS(schedulingAppCSS)}
      `
  }
}